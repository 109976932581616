<template>
  <v-container grid-list-xl fluids>
    <v-layout row wrap>
      <v-flex xs12 sm8>
        <!-- <highcharts class="chart" :options="chartOptions1"></highcharts> -->
        <v-sheet height="64">
          <v-toolbar flat>
            <v-btn
              outlined
              class="mr-4"
              color="grey darken-2"
              @click="setToday"
            >
              Hoy
            </v-btn>
            <v-btn fab text small color="grey darken-2" @click="prev">
              <v-icon small> mdi-chevron-left </v-icon>
            </v-btn>
            <v-btn fab text small color="grey darken-2" @click="next">
              <v-icon small> mdi-chevron-right </v-icon>
            </v-btn>
            <v-toolbar-title v-if="$refs.calendar">
              {{ $refs.calendar.title }}
            </v-toolbar-title>
            <v-spacer></v-spacer>
            <v-menu bottom right>
              <template v-slot:activator="{ on, attrs }">
                <v-btn outlined color="grey darken-2" v-bind="attrs" v-on="on">
                  <span>{{ typeToLabel[type] }}</span>
                  <v-icon right> mdi-menu-down </v-icon>
                </v-btn>
              </template>
              <v-list>
                <v-list-item @click="type = 'month'">
                  <v-list-item-title>Mes</v-list-item-title>
                </v-list-item>
                <v-list-item @click="type = 'week'">
                  <v-list-item-title>Semana</v-list-item-title>
                </v-list-item>
                <v-list-item @click="type = 'day'">
                  <v-list-item-title>Día</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </v-toolbar>
        </v-sheet>
        <v-sheet height="600">
          <v-calendar
            ref="calendar"
            v-model="focus"
            color="primary"
            :events="events"
            :event-color="getEventColor"
            :type="type"
            locale="es"
            @click:event="showEvent"
            @click:more="viewDay"
            @click:date="viewDay"
            @change="updateRange"
          ></v-calendar>
          <v-menu
            v-model="selectedOpen"
            :close-on-content-click="false"
            :activator="selectedElement"
            offset-x
          >
            <v-card color="grey lighten-4" min-width="350px" flat>
              <v-toolbar :color="selectedEvent.color" dark>
                <v-btn icon>
                  <v-icon>mdi-pencil</v-icon>
                </v-btn>
                <v-toolbar-title v-html="selectedEvent.name"></v-toolbar-title>
                <v-spacer></v-spacer>
                <v-btn icon>
                  <v-icon>mdi-heart</v-icon>
                </v-btn>
                <v-btn icon>
                  <v-icon>mdi-dots-vertical</v-icon>
                </v-btn>
              </v-toolbar>
              <v-card-text>
                <span v-html="selectedEvent.details"></span>
              </v-card-text>
              <v-card-actions>
                <v-btn text color="secondary" @click="selectedOpen = false">
                  Cancel
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-menu>
        </v-sheet>
      </v-flex>
      <v-flex xs12 sm4>
        <!-- <highcharts class="chart" :options="chartOptions2"></highcharts> -->
        <!-- <h4 class="grey--text text--darken-1 pb-2">Enlaces a Sistemas</h4> -->
        <v-card class="mx-auto mt-2">
          <v-card-title class="grey--text text--darken-1"
            >Enlaces a Sistemas</v-card-title
          >
          <v-divider></v-divider>
          <v-card-text>
            <p v-for="(item, i) in enlaces" :key="i">
              <v-btn color="info" :href="item.enlace" block target="_blank">{{
                item.titulo
              }}</v-btn>
            </p>
          </v-card-text>
        </v-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import Service from "../services/general";
import axios from "axios";
export default {
  data: () => ({
    loadingFile: false,
    serve: "",
    enlaces: [],

    focus: "",
    type: "month",
    typeToLabel: {
      month: "Mes",
      week: "Semana",
      day: "Día",
    },
    selectedEvent: {},
    selectedElement: null,
    selectedOpen: false,
    events: [],
    colors: [
      "blue",
      "indigo",
      "deep-purple",
      "cyan",
      "green",
      "orange",
      "grey darken-1",
    ],
    names: [
      "Meeting",
      "Holiday",
      "PTO",
      "Travel",
      "Event",
      "Birthday",
      "Conference",
      "Party",
    ],

    chartOptions1: {},
    chartOptions2: {},
  }),
  created() {
    this.serve = Service.getServe();
  },
  mounted() {
    this.getEnlaces();
    this.$refs.calendar.checkChange();

    this.chartOptions1 = {
      chart: {
        type: "spline",
      },
      title: {
        text: "Trámites atendidos",
      },
      series: [
        {
          data: [30, 50, 8, 2, 6, 4, 5, 5],
          color: "#6fcd98",
        },
      ],
    };
    this.chartOptions2 = {
      chart: {
        type: "column",
      },
      title: {
        text: "Trámites atendidos",
      },
      subtitle: {
        text: "Click the columns to view versions.",
      },
      xAxis: {
        type: "category",
      },
      yAxis: {
        title: {
          text: "Total percent market share",
        },
      },
      plotOptions: {
        series: {
          borderWidth: 0,
          dataLabels: {
            enabled: true,
            format: "{point.y:.1f}%",
          },
        },
      },
      series: [
        {
          name: "Browsers",
          colorByPoint: true,
          data: [
            {
              name: "Chrome",
              y: 62.74,
              drilldown: "Chrome",
            },
            {
              name: "Firefox",
              y: 10.57,
              drilldown: "Firefox",
            },
            {
              name: "Safari",
              y: 5.58,
              drilldown: "Safari",
            },
            {
              name: "Opera",
              y: 1.92,
              drilldown: "Opera",
            },
            {
              name: "Other",
              y: 7.62,
              drilldown: null,
            },
          ],
        },
      ],
    };
  },
  methods: {
    getEnlaces() {
      this.loadingFile = true;
      axios
        .get(Service.getBase() + "enlaces", Service.getHeader())
        .then((response) => {
          this.loadingFile = false;
          this.enlaces = response.data;
        })
        .catch((error) => {
          this.loadingFile = false;
          console.error("Error al cargar registros", error);
        });
    },

    viewDay({ date }) {
      this.focus = date;
      this.type = "day";
    },
    getEventColor(event) {
      return event.color;
    },
    setToday() {
      this.focus = "";
    },
    prev() {
      this.$refs.calendar.prev();
    },
    next() {
      this.$refs.calendar.next();
    },
    showEvent({ nativeEvent, event }) {
      const open = () => {
        this.selectedEvent = event;
        this.selectedElement = nativeEvent.target;
        setTimeout(() => {
          this.selectedOpen = true;
        }, 10);
      };

      if (this.selectedOpen) {
        this.selectedOpen = false;
        setTimeout(open, 10);
      } else {
        open();
      }

      nativeEvent.stopPropagation();
    },
    updateRange({ start, end }) {
      // this.events = events;
    },
    rnd(a, b) {
      return Math.floor((b - a + 1) * Math.random()) + a;
    },
  },
};
</script>
<style>
#custom-map {
  height: 500px;
  min-width: 310px;
  max-width: 800px;
  margin: 0 auto;
}
.loading {
  margin-top: 10em;
  text-align: center;
  color: gray;
}
</style>
